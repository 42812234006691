.header {
  align-items: flex-start;
  display: flex;
  flex: 1 0 0px;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: flex-end;
  overflow: visible;
  padding: 50px 25px;
  position: relative;
}

.header > div {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex: 1 0 0px;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 10px;
  height: min-content;
  justify-content: flex-end;
  overflow: visible;
  padding: 0;
  position: relative;
  margin: 0 auto;
}

.header > div > div {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
}

.header > div > div > p {
  font-family: var(--framer-blockquote-font-family, var(--framer-font-family, Inter, Inter Placeholder, sans-serif));
  font-size: 28px;
  letter-spacing: 0px;
  line-height: 36px;
  font-weight: 300;
  color: rgb(255, 255, 255);
}

.header h1 {
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 52px;
  color: rgb(255, 255, 255);
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  .header > div > div > p {
    font-size: 25px;
    line-height: 36px;
  }
  .header {
    padding: 30px 25px;
  }
  .header h1 {
    line-height: 40px;
    font-size: 32px;
  }
}