.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  padding: 0 25px;
}

// Use case start
.useCase {
  display: flex;
  padding: 30px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 40px;
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: 30px;
  background: #FFF;

  h3 {
    color: var(--Gray-1, #333);
    font-family: "Inter", "Inter Placeholder", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  h2 {
    color: var(--Gray-1, #333);
    font-family: "Inter", "Inter Placeholder", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  h3 a {
    text-decoration: none;
    color: var(--Gray-1, #333);
  }
  p {
    color: var(--Gray-1, #333);
    font-family: "Inter", "Inter Placeholder", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
  }
}

.useCase .col {
  display: flex;
  flex-direction: column;
  align-items: normal;
  gap: 20px;
  flex: 1 0 48%;
  background: rgba(255, 255, 255, 0.10);
  position: relative;
}

.useCase .OpenCaseButton {
  position: absolute;
  right: 30px;
}

.useCase .OpenCaseButton.top {
  top: 30px;
}
.useCase .OpenCaseButton.bottom {
  bottom: 30px;
}

.useCase .line {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.grid {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  
  .item {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  p {
    color: #000;
    font-family: "Inter", "Inter Placeholder", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 28px;
  }

  .label{
    font-family: "Inter", "Inter Placeholder", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 200% */
    text-transform: uppercase;
    & span {
      color: #BDBDBD;
      font-family: Geist;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }
  }
}

.useCase .col img {
  display: flex;
  width: 100%;
  border-radius: 25px;
}

// UC Ends

// Badges Start
// RIGHT BADGES
.useCase .col .badges {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.useCase .col .badges .badge {
  display: flex;
  height: 38px;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid rgba(231, 239, 253, 0.5);
  background: #F1F4FA;
}

.useCase .col .badges .badge span {
  color: #1D2F54;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
// Badges Ends

.content .row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.content .rounded img {
  display: flex;
  width: 100%;
}

.content .row .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  // flex: 1 0 0;
}

.content .row .right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content .row1 {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  // height: 347px;
}

.content .row1 > div {
  display: flex;
  // height: 347px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  // height: 150px;
}

.content .row .left, .content .row .right, .content .row1 > div {
  overflow: hidden;
  display: flex;
}

.content .rounded {
  display: flex;
  overflow: hidden;
  border-radius: 30px !important;
  width: 100%;
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  .content .row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .content .row1 > div {
    height: 150px;
  }

  .content .row .left, .content .row .right {
    // min-width: 375px;
  }

  .content .rounded {
    display: flex;
    overflow: hidden;
    border-radius: 20px !important;
  }

  .content .rounded img {
    display: flex;
    width: 100%;
  }

  .content .firstImage {
    display: flex;
    height: 150px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: space-between;
    flex-direction: column;
  }

  .useCase {
    padding: 20px;
    gap: 27px;
  
    h3 {
      font-size: 25px;
    }
    h2 {
      font-size: 22px;
    }
    p {
      font-size: 18px;
    }
  }

  .useCase .line {
    gap: 15px;
  }

  .useCase .col {
    gap: 20px;
  }

  .grid .label {
    font-size: 14px;
    line-height: 22px;
  }

  .useCase .col .badges .badge span {
    font-size: 16px;
  }

  .useCase .col img {
    border-radius: 22px;
  }

}