.navigation {
  align-content: center;
  align-items: center;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
  overflow: visible;
  padding: 10px 0;
  position: fixed;
  width: 900px;
  z-index: 1;
  margin: 0 auto;
  left: 50%;
    transform: translateX(-50%);
}

.navigation .container {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: hsla(0, 0%, 90%, .6);
  border-radius: 100px;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 54px;
  display: flex;
  padding: 2px 10px;
  justify-content: space-evenly;
  gap: 10px;
  flex: 1 0;
  box-shadow: 0px 16px 50px -20px rgba(0, 0, 0, 0.25);
  align-items: center;
  margin: 0 auto;
}

.navigation .userName {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  flex: 1 0;
  padding: 16px 8px 16px 2px;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.navigation .userName svg {
  display: flex;
  margin:  0 0 -7px 0;
}

.navigation .avatar {
  position: absolute;
  border-radius: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  aspect-ratio: 1 / 1;
  border-radius: 177px;
  flex: none;
  height: var(--framer-aspect-ratio-supported, 35px);
  position: relative;
  width: 35px;
}

.navigation .avatar img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
}

.navigation .links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navigation .links .borderRadius {
  border-radius: 100px;
}

.navigation ul {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navigation ul li {
  position: relative;
}

.navigation ul li span {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0 !important;
  top: 0 !important;
  inset: 0;
  z-index: -1;
}

.navigation ul li a {
  display: flex;
  width: 100px;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.navigation ul li a {
  color: var(--ColorBase-Mako, #444854);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
}
.navigation ul li a.activeLink {
  color: #000 !important;
}

.navigation ul li .active {
  border-radius: 200px;
  background: #FFF;
}

.navigation .contactMe {
  display: flex;
  height: 40px;
  padding: 0 3px 0 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 27.5px;
  background: rgb(28 28 28 / 10%);
}

.navigation .contactMe  {
  color: var(--ColorBase-Mako, #444854);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  .navigation {
    width: 85%;
    left: 50%;
      transform: translateX(-50%);
  }
  .navigation .userName, .navigation .contactMe {
    display: none;
  }
  .navigation ul {
    gap: 5px;
  }
  .navigation ul li a {
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .navigation {
    width: 100%;
    left: 50%;
      transform: translateX(-50%);
  }
}