.content {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  flex-direction: row;
  padding: 0 25px 55px 25px;
}

// LEFT
.content .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  flex: 1 0 60%;
  gap: 15px
}

// LEFT Expirience
.content .left .grid {
  width: 100%;
}

.content .left .grid .row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
}

.content .left .grid .row .heading {
  display: flex;
  padding: 10px 15px;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
  border-radius: 5px;
  background: #F7F7F7;
}

.content .left .grid .row .heading .logo a {
  display: flex;
  width: 30px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;  
}

.content .left .grid .row .heading .logo a svg {
  height: 30px;
}

.content .left .grid .row .heading .company {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 0;
  gap: 10px;
}

.content .left .grid .row .heading .company .companyName {
  color: #1D2F54;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  text-decoration: none;
}

.content .left .grid .row .heading .company .roleTitle {
  color: #1D2F54;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  align-self: stretch;
  text-decoration: none;
}

.content .left .grid .row .heading .date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.content .left .grid .row .heading .date p {
  color: #ACB1C3 !important;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */ 
}

.content .left .grid .row .description {
  display: flex;
  padding: 0px 20px;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
}

.content .left .grid .row .description ul {
  gap: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 20px 0;
  list-style-type: disc;
  padding: 0 0 25px 25px;
}

.content .left .grid .row .description ul li {
  color: #1D2F54;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px; /* 150% */
  // flex: 1 0 0;
  // display: flex;
}

// .content .left .grid .row .description ul {
//   list-style: georgian outside url('./assets/icons/companies/cricle_mark.svg');
// }

.content .left .grid .education {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 0 0 45px 0;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.content .left .grid .education .leftCol a,
.content .left .grid .education .leftCol p {
  color: #1D2F54;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-decoration: none;
}

.content .left .grid .education .leftCol p {
  font-weight: 400 !important;
}

.content .left .grid .education .leftCol p span {
  font-weight: 600 !important;
}
.content .left .grid .education .leftCol p span.rate {
  padding: 0 0 0 10px;
}

.content .left .grid .education .leftCol {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  align-items: stretch;
}

.content .left .grid .education .rightCol {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
}

.content .left .grid .education .rightCol p {
  color: #ACB1C3 !important;
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 177.778% */
}

// RIGHT
.content .right {
  display: flex;
  padding: 0px 10px 0  40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-left: 1px solid var(--Secondary-Lighter, #E1E7FE);
}

.content .right .grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  padding: 0 0 6px 0;
}

.content .right .grid .item {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.content .right .grid .item .icon {
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #F1F4FA;
  justify-content: center;
}

.content .right .grid .item .icon svg {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
}

.content .right .grid .item .info p {
  color: #79819A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.content .right .grid .item .info a {
  color: #0452BC;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-decoration: none;
}

.content .right .grid .item .info span {
  color: #1D2F54;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-decoration: none;
}

.content h3 {
  color: #1D2F54;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 128.571% */
}

.content h3.edu {
  width: 100%;
  padding: 0 0 10px 0;
  display: flex;
  border-bottom: 1px solid #E1E7FE;
  color: #1D2F54;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 128.571% */
}

.content h3.exp {
  align-self: stretch;
}

// RIGHT BADGES
.content .right .badges {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px;
  align-self: stretch;
  flex-wrap: wrap;
}

.content .right .badges .badge {
  display: flex;
  height: 43px;
  padding: 0 15px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid rgba(231, 239, 253, 0.50);
  background: #F1F4FA;
}

.content .right .badges .badge span {
  color: #1D2F54;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.content .right .interests ul {
  list-style-type: disc;
  padding:  0 0 0 25px;
}

.content .right .languages ul,
.content .right .interests ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
  align-self: stretch;
}

.content .right .languages ul li,
.content .right .interests ul li {
  color: #1D2F54;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
}

.content .right .languages ul li span,
.content .right .interests ul li span {
  flex: 1 0 0;
  color: #1D2F54;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}

@media screen and (min-width: 375px) and (max-width: 768px) {
  .content {
    flex-wrap: wrap;
    gap: 0;
  }
  .content .left .grid .row .heading {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0;
  }
  .content .left .grid .row .heading .date {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: center;
    /* flex: 1 0 8%; */
    padding: 0 0 0 40px;
    flex-direction: row;
    gap: 20px;
  }
  .content .left .grid .row .heading .date p {
    display: flex;
    gap: 10px;
  }
  .content .right {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-left: 0;
  }

  //Education
  .content .left .grid .education {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 0 20px 0;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .content .left .grid .education .rightCol p {
    display: flex;
  }
}