.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  padding: 0 25px 55px 25px;
}

.content .container {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  border-radius: 30px;
  background: #FFF;
}

.content .container .row {
  display: flex;
  align-items: center;
  gap: 50px;
  align-self: stretch;
}

.content .container .row > div {
  flex: 1 0 0;
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
}
.content .container .row p > span {
  display: flex;
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
}
.content .container .row p > span.annotation {
  padding: 20px 0 0 0;
}

.content .container .row.paragraph p  {
  display: flex;
  color: #1D2F54 !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 20px;
}

.cols {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
}

.cols > div {
  display: flex;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  align-content: stretch;
  justify-content: flex-start;
  align-items: stretch;
}

.cols .left {
  border-radius: 30px;
}

.cols .right {
  display: flex;
  gap: 30px;
}

.cols .right > div p {
  line-height: 24px;
  color: #1D2F54 !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 20px;
}

.cols .right > div {
  display: flex;
  flex: 1 0 0;
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  background: #FFF;
  padding: 40px;
  border-radius: 30px;
}

.cols .right > div p {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: stretch;
}

.cols .right > div .years {
  display: flex;
  color: #F34A4A;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.cols .right > div .expirience {
  display: flex;
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.cols .right > div .solutions {
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}

.cols .right > div .solutions > span {
  font-weight: 700;
}

.cols .right > div .except {
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.cols .right > div .believe {
  display: flex;
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cols .right > div .letsConnect {
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

.cols .right > div .letsConnect > span {
  font-weight: 700;
}

.content .container .row p .number {
  display: flex;
  color: #D0D0D0;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.content .container .row p .serviceName {
  color: #1D2F54;
  font-family: "Inter", "Inter Placeholder", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cols .row .left img {
  display: flex;
}

@media only screen and (min-width: 375px) and (max-width: 768px) {
  .content, .cols, .cols .right {
    gap: 20px;
  }
  .container {
    padding: 30px 25px;
  }
  .content .container .row > div {
    font-size: 16px;
    line-height: 25px;
  }
  .content .container .row > div p > span {
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;
    padding: 10px 0;
  }
  .content .container .row {
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    align-content: flex-start;
    gap: 10px;
    flex-direction: column;
  }

  .container .row {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
  }
  .content .container .row > div {
    flex: 1 0 50%;
  }

  .cols {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .content .container .row.paragraph p {
    gap: 5px
  }

  .content .container .row p .serviceName, .content .container .row p .number {
    font-size: 25px;
  }
  .cols {
      flex-direction: column;
  }
  .cols .right > div .years {
    font-size: 35px;
  }
  
  .cols .right > div .expirience {
    font-size: 25px;
  }
  
  .cols .right > div .solutions {
    font-size: 20px;
  }

  .cols .right > div p {
    gap: 10px;
  }
  .cols .right > div .believe {
    font-size: 20px;
  }
  .cols .right > div .letsConnect {
    font-size: 20px;
    line-height: 35px;
  }
  .cols .right > div .letsConnect > span {
    font-size: 22px;
  }

  .content .container .row p > span.annotation {
    padding: 10px 0;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1110px) {
  .content .container .row p .serviceName, .content .container .row p .number {
    font-size: 30px;
  }

  .content .container .row p > span.annotation {
    padding: 10px 0;
  }
  

  .content .container .row {
    display: flex;
    align-items: flex-start;
    gap: 50px;
    align-self: stretch;
  }

  .content .cols .left {
    max-height: 500px;
  }

  .cols {
      flex-direction: column;
  }

  .cols .right > div .years {
    font-size: 48px;
  }

  .cols .right > div .expirience {
    font-size: 40px;
  }

  .cols .right > div .solutions {
    font-size: 25px;
  }

  .cols .right > div p {
    flex-direction: column;
  }
}